import { motion } from 'framer-motion';
import { getImageURL } from '../../Utility/Utility';

function MapImage({
  selected, mapX, mapY, onMouseDown,
}) {
  return (
    <div
      className="m-0 p-0"
      style={{
        width: 32, height: 32, background: 'white', zIndex: selected ? 1 : 0,
      }}
    >
      <motion.img
        style={{
          width: 32, height: 32, background: 'white', cursor: 'pointer',
        }}
        alt={`${mapX}x${mapY}`}
        src={getImageURL(mapX, mapY)}
        onMouseDown={onMouseDown}
        whileHover={{
          borderRadius: 10,
          transition: {
            type: 'spring',
            stiffness: 200,
            damping: 5,
          },
        }}
      />
    </div>
  );
}

export default MapImage;
