/* eslint-disable max-len */
/* eslint-disable no-continue */
import { motion } from 'framer-motion';
import { useState } from 'react';
import MapImage from './Components/Images/MapImage';
import { getImageURL } from './Utility/Utility';

function App() {
  const [highlighted, setHighlighted] = useState(false);
  const [hlX, setHlX] = useState(-1);
  const [hlY, setHlY] = useState(-1);
  const [showWindows, setShowWindows] = useState(false);

  const [xCols] = useState(Math.floor(window.innerWidth / 32));
  const [yCols] = useState(Math.floor(window.innerHeight / 32));

  const highlightedVariants = {
    hl: {
      opacity: 1,
      scale: 1,
      x: hlX * 32,
      y: (hlY + 1) * 32 + 10,
      borderRadius: showWindows ? 0 : 10,
      transition: {
        type: 'spring',
        stiffness: 600,
        damping: 30,
      },
    },
    nhl: {
      opacity: 0,
      scale: 0,
      x: (hlX - 1) * 32,
      y: (hlY + 1) * 32 + 10,
      borderRadius: showWindows ? 0 : 10,
    },
  };

  const windowVariants = {
    hl: {
      pointerEvents: 'auto',
      cursor: 'pointer',
      opacity: 1,
      borderRadius: 0,
      transition: {
        duration: 0.5,
      },
    },
    nhl: {
      pointerEvents: 'none',
      cursor: 'auto',
      opacity: 0,
      borderRadius: 50,
    },
  };

  const windowArray = [{ x: -1, y: -1 }, { x: -1, y: 0 }, { x: -1, y: 1 }, { x: 0, y: -1 }, { x: 0, y: 1 }, { x: 1, y: -1 }, { x: 1, y: 0 }, { x: 1, y: 1 }];

  return (
    <div style={{ background: '#131313', height: '100vh', overflow: 'hidden' }}>
      <div style={{
        display: 'grid', gridTemplateColumns: `repeat(${xCols}, 32px)`, overflow: 'hidden',
      }}
      >
        {Array.from(Array(yCols)).map((_y, y) => Array.from(Array(xCols)).map((_x, x) => (
          <MapImage
            key={`Map Image at ${x},${y}`}
            mapX={x}
            mapY={y}
            selected={!highlighted || (x === hlX && y === hlY)}
            onMouseDown={() => {
              if (x === hlX && y === hlY) setHighlighted(!highlighted);
              else if (!highlighted) {
                setHighlighted(true);
              }

              setHlX(x);
              setHlY(y);

              if (showWindows) setShowWindows(false);
            }}
          />
        )))}

        <div className={`overlay ${highlighted ? 'overlay-visible' : 'overlay-invisible'}`} />

        <motion.img
          className="m-0 p-0"
          style={{
            width: 128, height: 128, position: 'absolute', background: 'white', boxShadow: '0px 0px 5px 5px #00000077', cursor: 'pointer', zIndex: 2,
          }}
          alt={`${hlX} x${hlY}`}
          src={getImageURL(hlX, hlY)}
          variants={highlightedVariants}
          initial="nhl"
          animate={highlighted ? 'hl' : 'nhl'}
          onMouseDown={() => setShowWindows(!showWindows)}
        />
        {windowArray.map((item) => (
          <motion.img
            key={`Focused Map Item at ${item.x},${item.y}`}
            className="m-0 p-0"
            style={{
              width: 128, height: 128, position: 'absolute', x: hlX * 32 + item.x * 128, y: hlY * 32 + item.y * 128 + 42, background: 'white', zIndex: 2,
            }}
            alt=""
            src={getImageURL(hlX + item.x, hlY + item.y)}
            variants={windowVariants}
            initial="nhl"
            animate={showWindows ? 'hl' : 'nhl'}
            onMouseDown={() => setShowWindows(!showWindows)}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
